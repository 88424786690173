import React from "react"
import { graphql } from "gatsby"

// s4n
import FragmentBlogDetails from '../../model/fragments/nodeBlog/blog-details'
import { injectIntl } from "gatsby-plugin-intl"
import GeneralUtils from './../../utils/common'
import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import BlogTemplate from "./../../components/s4n/Blog/details"


const ComponentStateless = (props) => {
  const { data, intl } = props;
  const node = data.nodeBlog;

  // prepare/extract node data
  const nodeData = {
    title: node.title,
    img: node.relationships.field_image && node.relationships.field_image.localFile ? node.relationships.field_image.localFile.childImageSharp.fluid : null,
    bodyValue: node.body ? node.body.value : null,
    // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
    bodyValueEnchanced: useParseInlineImages(node, true),
    created: GeneralUtils.dateFormat(node.created, null, intl.locale),
    node, // === node: node,
  };

  return <BlogTemplate data={nodeData} translate={intl.formatMessage} />
}

export default injectIntl(ComponentStateless)

// this query is Promie, we export it to let Gatsby fill variables from context from gatsby-node.js (id )
// and pass back to this file as props to below component
export const query = graphql`
    query(
      $id: String!,
      $drupal_id: String!,
      $langcode: String!
    ) {
    nodeBlog(
      id: {
        eq: $id
      },
      drupal_id: {
        eq: $drupal_id
      },
      langcode: {
        eq: $langcode
      }
    ) {
        ...FragmentBlogDetails
    }
  }
`