/**
 * Just view/template component, no model/data logic!
 */
import React from "react"
import Layout from "./../../layout"
import Img from 'gatsby-image'
import SEO from "./../../seo"

// s4n
import Link from './../Link'
// import "./../../assets/sass/templates/node/_blog.scss"
import classNames from 'classnames';
// import LogoHeuresis from './../../../images/custom/heuresis-logo.svg'



const ComponentStateless = (props) => {
  const { data, translate: t } = props; // alias t === translate function

  // console.log(data.img.src)

  const CoverClass = classNames({
    'wp-block-cover _has-background-dim': true,
    's4n-has-no-background': !data.img,
  });

  let styleCover = {
    'background-color': `rgba(0, 51, 153, 1)`,
    'background-position': `center`, // `49.4565% 0%`,
    'background-repeat': `no-repeat`,
  }

  let meta = [];

  if (data.img) {
    styleCover['background-image'] = `url('${data.img.src}')`;

    meta.push(
      {
        property: `image`,
        content: `${process.env.SOFT4NET_STATIC_BASE_URL}${data.img.src}`,
      }, 
      {
        property: `og:image`,
        content: `${process.env.SOFT4NET_STATIC_BASE_URL}${data.img.src}`,
      },
      {
        property: `twitter:image`,
        content: `${process.env.SOFT4NET_STATIC_BASE_URL}${data.img.src}`,
      }
    );
  }

  return (
    <Layout node={data.node}>
        <SEO title={`${data.title} | Blog`} keywords={`blog`} meta={meta} nodeId={data.node.drupal_internal__nid} />

        {/* Old header */}
        {true && 
          <div className="container">
            <div 
              className={CoverClass}
              // data-entity-type="file" data-entity-uuid="27469459-3f6a-497d-8a82-fd55a1065a67" data-image-style="original" 
              style={styleCover}
            >
              <div className="wp-block-cover__inner-container">
                <h1 className="text-center pb-1 pb-sm-3 pb-lg-5">{ data.title }</h1>
                <span className="posted_by d-block text-center text-white">{ data.created }</span>
                {/* <h2 className="has-text-align-center">Zapraszamy do współpracy</h2> */}
                {/* <p style="font-size: 24px; text-align: center;">Chcemy dzielić się naszym doświadczeniem, wiedzą,oraz wypracowanymi i sprawdzonymi rozwiązaniami i narzędziami. Szukamy osób indywidualnych i instytucji szkoleniowych do współpracy. Niezależnie od formy współpracy, stawiamy na jakość, skuteczność, troskę oraz uczciwość. </p> */}
              </div>
            </div>
          </div>
        }

        {/* New header */}
        {false && <section className="page-title-alt _bg-primary position-relative">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="text-white font-tertiary text-center">{ data.title }</h1>
                <span className="posted_by d-block text-center text-white">{ data.created }</span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Img fluid={ data.img } className="rounded card-img-top" alt={ data.title } />
              </div>
            </div>
          </div>
        </section>}

        {/* <section id="blog-page" className="blog-page">
            <div className="container">
                <h1>{ data.title }</h1>
                <span className="posted_by">{ data.created }</span>
                <div style={{ maxWidth: `900px`, marginBottom: `1.45rem`, width: `100%` }}>{ data.img && <Img fluid={ data.img } /> }</div>
            </div>
        </section> */}

        {/* <div class="wp-block-soft4net-container-block container"> */}
        {/* <div style={{ marginTop: `10rem` }} className=""> */}
          { data.bodyValueEnchanced }
        {/* </div> */}
        {/* </div> */}

        {/* <div dangerouslySetInnerHTML={{ __html: data.bodyValue }}></div> */}

        <div className="container">
          <div className="row">
            <div className="col pb-5 text-center">
              <Link to={`/blog`} title={`Blog`} className="btn btn-primary">Blog</Link>
            </div>
          </div>
        </div>

    </Layout>
  )
}

export default ComponentStateless